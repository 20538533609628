const getters = {
    getCreativeCamps(state) {
        const creativeCamps = state.creativeCamps.map((c) => {
            return {
                code: c.campaign_code,
                parent: c.parent,
                pointCode: c.point_code,
                startDate: c.start_date,
                endDate: c.end_date,
                pubIds: c.details?.crea?.pubIds,
            };
        });
        return creativeCamps;
    },

    currentBreakPoint: (state) => {
        const { windowWidth } = state;
        if (windowWidth >= $themeBreakpoints.xl) return 'xl';
        if (windowWidth >= $themeBreakpoints.lg) return 'lg';
        if (windowWidth >= $themeBreakpoints.md) return 'md';
        if (windowWidth >= $themeBreakpoints.sm) return 'sm';
        return 'xs';
    },

    availableMediasCode(state) {
        return state.mediaMetrics.map((media) => media.media);
    },

    availableMedias(state) {
        return state.mediaMetrics.map((media) => {
            return {
                value: media.media,
                text: media.label,
                details: media.geolinkDetails,
            };
        });
    },

    getCriteriaTF1(state) {
        let criteria = [];
        criteria.push({ value: [], text: 'Aucun critère' });
        const data = state.potentialCriteriasTF1.map((criteria) => {
            return {
                value: criteria.id,
                text: criteria.nom,
            };
        });
        criteria.push(...data);
        criteria.sort((a, b) => a.text.localeCompare(b.text));
        return criteria;
    },
    getCriteria(state) {
        let criteria = [];
        criteria.push({ value: null, text: 'Aucun critère' });
        const data = state.potentialCtriterias.map((criteria) => {
            return {
                value: criteria.targetingId,
                text: criteria.targetingName,
                dataTargetingType: criteria.dataTargetingType,
            };
        });
        criteria.push(...data);
        criteria.sort((a, b) => a.text.localeCompare(b.text));
        return criteria;
    },

    getAvailableImpresssions(state) {
        return state.availableImpressions;
    },

    getStatusFilters(state) {
        return state.statusFilter;
    },

    getModifiedCampaigns(state) {
        return state.campaignsModified;
    },

    getModifiedMedias(state) {
        return state.mediasModified;
    },

    getStatusSubFilter(state) {
        return state.statusSubFilter;
    },

    getCurrentCampaignsList(state) {
        return state.currentCampaignsList;
    },

    getCurrentAlertsList(state) {
        return state.currentAlertsList;
    },

    getGlobalAlerts(state) {
        return state.globalAlerts;
    },

    getCurrentMediasList(state) {
        return state.currentMediasList;
    },

    getMediasList(state) {
        return state.capitalizedMedias;
    },

    getMediasFromAlerts(state) {
        return state.capitalizedMediasAlerts;
    },

    getCurrentPageCampaigns(state) {
        return state.globalConfigPageCampaigns.currentPage;
    },
    getGlobalConfigPageCampaigns(state) {
        return state.globalConfigPageCampaigns;
    },

    getAlertsTypes(state) {
        return state.alertTypes;
    },

    getGroupedAlerts(state) {
        return state.groupedAlerts;
    },

    getBrandsList(state) {
        return state.capitalizedBrands;
    },

    getBrandsCampaigns(state) {
        return state.brandsCampaigns;
    },

    getBrandsMedias(state) {
        return state.brandsMedias;
    },

    getBrandsListAlerts(state) {
        return state.brandsAlerts;
    },

    formatToDate: () => (date) => {
        let tabDate;
        if (date.includes('/')) {
            tabDate = date.split('/');
        } else if (date.includes('-')) {
            tabDate = date.split('-');
        } else {
            console.error('error date format : ' + date);
            return null;
        }

        let trueDate;
        if (tabDate[0].length < 3) {
            trueDate = new Date(tabDate[2], tabDate[1] - 1, tabDate[0]);
        } else if (tabDate[0].length == 4) {
            trueDate = new Date(tabDate[0], tabDate[1] - 1, tabDate[2]);
        }

        return trueDate;
    },

    formatToDashDate: () => (rawDate) => {
        let formatedDate = rawDate.toLocaleDateString('en-GB');
        const dashDate = formatedDate.split('/').reverse().join('-');
        return dashDate;
    },

    successToast: () => (title) => {
        return {
            component: 'toast',
            props: {
                title: title,
                icon: 'CheckCircleIcon',
                variant: 'success',
            },
        };
    },

    errorToast: () => (title) => {
        return {
            component: 'toast',
            props: {
                title: title,
                icon: 'x-circle',
                variant: 'danger',
            },
        };
    },

    modalContext:
        () =>
        (color = 'primary') => {
            return {
                title: 'Please Confirm',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: color,
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
            };
        },
};

export default getters;
